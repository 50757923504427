import { init } from '@elastic/apm-rum'

/**
 * ONLY FOR PRODUCTION ENVIRONMENT.
 *
 * @param {string} serviceName Name of the service to be monitored with APM RUM.
 * @returns {ApmBase} APM RUM Agent
 */
export default function initApm(serviceName: string) {
  return init({
    serviceName,
    active: Boolean(process.env.NODE_ENV === 'production'),
    environment: process.env.NODE_ENV,
    serverUrl: 'https://luegopagos.apm.eastus2.azure.elastic-cloud.com',
  })
}
