/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'

import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAuthentication } from '@/context/AuthenticationContext'

const UserPerfil = () => {
  const { userData, isAuthorized, getDataUser } = useAuthentication()

  useEffect(() => {
    getDataUser()
  }, [isAuthorized])

  return (
    <>
      {userData && isAuthorized ? (
        <section className="flex gap-3">
          <div className="mt-1 flex flex-col justify-center text-right text-sm text-white sm:text-base">
            <span>Hola,</span>
            <span className="font-black">{userData.firstName}</span>
          </div>
          <div>
            <Link href={'/cuenta'}>
              <div className="flex items-center justify-center rounded-full text-[#E2E2E2]">
                {userData.profilePicture ? (
                  <Image
                    src={userData.profilePicture.url}
                    alt={userData.profilePicture.name}
                    className="rounded-full"
                    height={50}
                    width={50}
                  />
                ) : (
                  <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white text-[#E2E2E2]">
                    <Image
                      src="https://luegopago.blob.core.windows.net/luegopago-uploads/manito-luegopago-azul-2.png"
                      alt="Logo luegopago"
                      className="rounded-full"
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            </Link>
          </div>
        </section>
      ) : (
        <section className="flex gap-3">
          <div className="mt-1 flex flex-col justify-center text-right text-sm text-white sm:text-base">
            <Link href={'/cuenta'}>
              <span className="font-bold">Iniciar sesión</span>
            </Link>
          </div>
          <div>
            <Link href={'/cuenta'}>
              <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white text-[#E2E2E2]">
                <div className="w-8">
                  <FontAwesomeIcon icon={faUser} />
                </div>
              </div>
            </Link>
          </div>
        </section>
      )}
    </>
  )
}

export default UserPerfil
