/* eslint-disable @next/next/no-img-element */
import { NextPage } from 'next'

const MaintenanceMode: NextPage = () => {
  return (
    <div className="relative">
      <div className="md:grid md:grid-cols-2">
        <div className="relative h-[30rem] overflow-hidden bg-lipa md:h-screen md:overflow-visible">
          <div className="flex flex-col justify-center p-4 md:px-36">
            <img
              className="w-64 md:w-96"
              src="https://luegopago.blob.core.windows.net/luegopago-uploads/website/luegopago-logo.png"
              alt="Luegopago Logo"
            />
          </div>
          <img
            className="absolute -bottom-6 w-full md:bottom-0 md:left-24"
            src="https://luegopago.blob.core.windows.net/luegopago-uploads/website/maintenance_person.png"
            alt="Persona con las manos arriba"
          />
        </div>
        <div className="flex h-auto flex-col justify-center bg-tamagotchi px-4 py-8 text-white md:h-screen md:px-24 lg:px-36">
          <h1 className="text-7xl font-bold">Tranqui</h1>
          <h3 className="my-2 text-5xl font-bold">pronto volveremos...</h3>
          <p className="my-6 text-3xl font-semibold">
            Estamos trabajando en una mejor experiencia para ti.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MaintenanceMode
