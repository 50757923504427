/* eslint-disable no-console */
import { Fragment, useEffect, useState } from 'react'

import { faDownload, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Transition } from '@headlessui/react'

import IconButton from '../atoms/IconButton'

interface DeferredPrompt extends Event {
  prompt(): void
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>
}

interface AppState {
  showInstallButton: boolean
  deferredPrompt: DeferredPrompt | null
}

const InstallApp = () => {
  const [state, setState] = useState<AppState>({
    showInstallButton: false,
    deferredPrompt: null,
  })

  useEffect(() => {
    const beforeInstallPromptHandler = (event: Event) => {
      event.preventDefault()
      const deferredPrompt = event as DeferredPrompt
      setState({ showInstallButton: true, deferredPrompt })
    }

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler)

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        beforeInstallPromptHandler
      )
    }
  }, [])

  const handleInstallClick = () => {
    if (state.deferredPrompt) {
      state.deferredPrompt.prompt()
      state.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('El usuario ha aceptado la instalación')
        } else {
          console.log('El usuario ha rechazado la instalación')
        }
        setState({ showInstallButton: false, deferredPrompt: null })
      })
    }
  }

  const onClose = () => {
    setState({ showInstallButton: false, deferredPrompt: null })
  }

  return (
    <div className="absolute bottom-[70px] w-full cursor-pointer md:bottom-[80px]">
      <Transition appear show={state.showInstallButton} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0 translate-y-full"
          enterTo="opacity-100 translate-y-0"
          leave="ease-in duration-500"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-full"
        >
          <div className="flex h-10 w-full items-center transition-all">
            <div className="flex h-10 w-10 items-center bg-tamagotchi pl-3 text-white opacity-90">
              <FontAwesomeIcon icon={faDownload} />
            </div>
            <div
              className="flex h-10 w-full items-center justify-center bg-tamagotchi opacity-90"
              onClick={handleInstallClick}
            >
              {state.showInstallButton && (
                <p className="text-base font-semibold text-white">
                  Instala y descubre nuestra App
                </p>
              )}
            </div>
            <div className="flex h-10 items-center bg-tamagotchi text-white">
              <IconButton
                type="button"
                icon={faXmark}
                onClick={onClose}
                size="lg"
              />
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </div>
  )
}

export default InstallApp
