/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from 'axios'
import { toast } from 'sonner'

import axiosInstanceClient from '@/common/axiosClient.interceptor'

import {
  codeValidationResponse,
  loginUser,
  loginUserOTP,
  loginUserToken,
  SendCode,
  SendCodeResponse,
  ValidationResponse,
} from '@/types/Users'

const config: AxiosRequestConfig = {
  baseURL: `${process.env.NEXT_PUBLIC_AUTENTICATION_LOGIN}/api/v1`,
}
interface ILoginService {
  loginUser: (body: loginUser) => Promise<void | loginUserToken>
  loginUserOTP: (body: loginUserOTP) => Promise<void | loginUserToken>
  sendCode: (body: SendCode) => Promise<void | SendCodeResponse>
  codeValidation: (
    body: ValidationResponse
  ) => Promise<void | codeValidationResponse>
}

const LoginService: ILoginService = {
  loginUser: (body) =>
    axiosInstanceClient
      .post<loginUserToken>(
        `Auth?code=${process.env.NEXT_PUBLIC_CODE_LOGIN}`,
        body,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success('Sesión iniciada')
          return response.data
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error('Sesión finalizada.')
          localStorage.removeItem('auth-token')
          sessionStorage.removeItem('lastPath')
        }
        if (error.response.status === 404) {
          toast.error('Usuario o contraseña incorrecta.')
        }
      }),
  loginUserOTP: async (body) => {
    try {
      const response = await axiosInstanceClient.get<loginUserToken>(
        `/OTP/login-Validation?PhoneNumber=${body.numberPhone}&Code=${body.code}`,
        config
      )
      if (response.status === 200) {
        toast.success('Sesión iniciada.')
      }
      return response.data
    } catch (error: any) {
      if (error?.response.status === 400) {
        toast.error(`Código inválido o ha expirado.`)
      }
      if (error?.response.status === 404) {
        toast.error(
          'Usuario no registrado, ¡Regístrate y disfruta de todas las ventajas como usuario registrado!'
        )
        return error?.response.status
      }
    }
  },
  sendCode: (body) =>
    axiosInstanceClient
      .post<SendCodeResponse>(`/OTP/Send-Code`, body, config)
      .then((response) => {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error('Error en la envio del código.')
        }
      }),
  codeValidation: (body) =>
    axiosInstanceClient
      .post<codeValidationResponse>(`/OTP/Code-Validation`, body, config)
      .then((response) => {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error('Error en la validación del código.')
        } else {
          toast.error(`${error.response.data.message}`)
        }
      }),
}

export default LoginService
