import { faGear, faGrid2 } from '@fortawesome/pro-regular-svg-icons'

import { NavBarItems } from '@/components/types'

export const navBarItems: NavBarItems[] = [
  {
    icon: faGrid2,
    href: '/',
    label: 'Servicios',
  },
  // {
  //   icon: faBell,
  //   href: '/notificaciones',
  //   label: 'Notificaciones',
  // },
  {
    icon: faGear,
    href: '/cuenta?tab=datos-personales',
    label: 'Mi cuenta',
  },
]
