/* eslint-disable import/no-anonymous-default-export */
import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/nextjs'

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const cache = new InMemoryCache({
  resultCaching: false,
})

const link = new HttpLink({
  uri: process.env.NEXT_PUBLIC_STRAPI_API,
})

const linkError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors || networkError) {
    Sentry.captureException(graphQLErrors || networkError)
  }
})

export default new ApolloClient({
  link: ApolloLink.from([linkError, link]),
  cache,
  defaultOptions,
})
