import useSWR from 'swr'

import StrapiClient from '@/services/Strapi/Client'
import { InactivityScreenQuery } from '@/services/Strapi/Queries/Hometopup.query'

import { HomeTopUpQueryInterface } from '@/types/strapi/Queries.types'

const useInactivityScreen = () => {
  const { data, mutate } = useSWR(
    {
      query: InactivityScreenQuery,
    },
    (args) => StrapiClient.query<HomeTopUpQueryInterface>(args),
    {
      revalidateOnFocus: true,
    }
  )

  return {
    data: data?.data.paymentsTopUp.inactivityScreen,
    mutate,
  }
}

export default useInactivityScreen
