import Link from 'next/link'
import { useRouter } from 'next/router'

import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import InstallApp from './InstallApp'
import { navBarItems } from '@/constants/layout.constants'

const TabBar = () => {
  const router = useRouter()
  return (
    <nav className="fixed bottom-0 flex h-[60px] w-full flex-col items-center justify-evenly bg-[#FBFBFB] pb-2 shadow-gray-700 md:relative md:h-[100px]">
      <div className="z-10 flex w-full justify-evenly bg-[#FBFBFB]">
        {navBarItems.map(({ href, icon, label }, key) => (
          <Link href={href} key={key}>
            <div
              className={clsx(
                router.asPath === href
                  ? 'scale-90 rounded-lg p-1 text-tamagotchi'
                  : 'my-2 scale-100',
                'ease flex w-full flex-col items-center gap-1 transition duration-500 hover:text-tamagotchi'
              )}
            >
              <div className="w-5">
                <FontAwesomeIcon icon={icon} />
              </div>
              <p className="text-base">{label}</p>

              {router.asPath === href && (
                <div className="h-2 w-2 rounded-full bg-tamagotchi"></div>
              )}
            </div>
          </Link>
        ))}
      </div>
      <InstallApp />
    </nav>
  )
}

export default TabBar
