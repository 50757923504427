/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import jwt_decode from 'jwt-decode'
import { toast } from 'sonner'

import LoginService from '@/services/Users/login.service'
import UserService from '@/services/Users/user.service'
import { useDataLogRocket } from '@/hooks/dataLogRocket'

import { getUserData, IJwtDecode } from '@/types/Users'

export interface IAuthenticationContext {
  isAuthorized: boolean
  loading: boolean
  clearOTP: boolean
  isRegisted: boolean
  setIsRegisted: (data: boolean) => void
  setIsAuthorized: (data: boolean) => void
  logout: () => void
  login: (email: string, password: string) => void
  loginOTP: (numberPhone: string, code: string) => void
  userData: getUserData
  getDataUser: () => void
  setUserData: (data: getUserData) => void
  setPaymentDataUser: any
  paymentDataUser: any
  setClearOTP: (data: boolean) => void
}

const AuthenticationContext = createContext<IAuthenticationContext>({
  isAuthorized: false,
  loading: false,
  clearOTP: false,
  isRegisted: true,
  logout: () => {
    return
  },
  login: () => {
    return
  },
  loginOTP: () => {
    return
  },
  userData: {
    id: '',
    enabled: true,
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    typeDocument: 1,
    document: '',
    birthday: new Date(),
    profilePicture: {
      url: '',
      name: '',
    },
    addresses: [
      {
        street: '',
        addressComplement: '',
        city: '',
        state: '',
        country: '',
        isPrincipal: true,
        roadType: '',
        roadNumber: '',
        plateNumber: '',
        intersectionNumber: '',
        coords: {
          latitude: 0,
          longitude: 0,
        },
        placeId: '',
      },
    ],
  },
  getDataUser: () => {
    return
  },
  setUserData: () => {
    return
  },
  setClearOTP: () => {
    return
  },
  setIsRegisted: () => {
    return
  },
  setIsAuthorized: () => {
    return
  },
  setPaymentDataUser: {},
  paymentDataUser: {},
})

export default function AuthenticationProvider({
  children,
}: {
  children: ReactNode
}) {
  const { dataLogRocket } = useDataLogRocket()
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
  const [isRegisted, setIsRegisted] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [userData, setUserData] = useState<any>()
  const [paymentDataUser, setPaymentDataUser] = useState<any>()
  const [clearOTP, setClearOTP] = useState<boolean>(false)

  const login = async (userName: string, password: string) => {
    setLoading(true)
    const response = await LoginService.loginUser({ userName, password })
    if (response) {
      localStorage.setItem('auth-token', JSON.stringify(response))
      setIsAuthorized(true)
      getDataUser()
    }
  }

  const loginOTP = async (numberPhone: string, code: string) => {
    const response: any = await LoginService.loginUserOTP({
      numberPhone,
      code,
    })
    setLoading(true)
    if (response && response === 404) {
      setIsRegisted(false)
    }
    if (response && response.accessToken) {
      localStorage.setItem('auth-token', JSON.stringify(response))
      setIsAuthorized(true)
      setIsRegisted(true)
      getDataUser()
    } else {
      setClearOTP(true)
    }
    setLoading(false)
  }

  const logout = () => {
    const token = localStorage.getItem('auth-token')
    if (!token) return
    localStorage.removeItem('auth-token')
    sessionStorage.removeItem('lastPath')
    setIsAuthorized(false)
    toast.success('Sesión finalizada.')
  }

  const getDataUser = async () => {
    if (!isAuthorized) return
    const token = JSON.parse(localStorage.getItem('auth-token') || '{}')
    const tokenDecoded: IJwtDecode = jwt_decode(token.accessToken)
    const data = await UserService.getUser(tokenDecoded.sub)
    if (data) {
      setUserData(data)
      dataLogRocket(data.email, {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phoneNumber,
      })

      setLoading(false)
    }
  }

  const checkToken = () => {
    if (typeof localStorage === 'undefined') return
    const token = localStorage.getItem('auth-token')
    setIsAuthorized((prev) => {
      if (token) return true
      return prev
    })
  }

  useEffect(() => {
    checkToken()
  }, [])

  return (
    <AuthenticationContext.Provider
      value={{
        isRegisted,
        setIsRegisted,
        setIsAuthorized,
        clearOTP,
        setClearOTP,
        isAuthorized,
        loading,
        logout,
        login,
        loginOTP,
        userData,
        getDataUser,
        setUserData,
        setPaymentDataUser,
        paymentDataUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export function useAuthentication() {
  return useContext(AuthenticationContext)
}

export function Authorized({
  children,
}: {
  children: ReactNode
}): JSX.Element | null {
  const { isAuthorized } = useAuthentication()
  return isAuthorized ? <div>{children}</div> : null
}

export function Unauthorized({
  children,
}: {
  children: ReactNode
}): JSX.Element | null {
  const { isAuthorized } = useAuthentication()
  return !isAuthorized ? <div>{children}</div> : null
}
