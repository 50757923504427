import LogRocket from 'logrocket'

interface DataUser {
  firstName: string
  lastName: string
  email: string
  phone: string
}
type DataLogRocketModel = {
  dataLogRocket: (dentifierEmail: string, data: DataUser) => void
}
export const useDataLogRocket = (): DataLogRocketModel => {
  const dataLogRocket = (identifierEmail: string, data: DataUser) => {
    LogRocket.identify(`${identifierEmail}`, {
      name: `${data?.firstName} ${data?.lastName}`,
      email: `${data?.email}`,
    })
  }
  return { dataLogRocket }
}
