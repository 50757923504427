import { AxiosRequestConfig } from 'axios'
import { toast } from 'sonner'

import axiosInstanceClient from '@/common/axiosClient.interceptor'

import {
  checkRegisterUser,
  checkUserRegistered,
  getUserData,
  RegisterResponse,
  registerUser,
  updateUser,
} from '@/types/Users'

const config: AxiosRequestConfig = {
  baseURL: `${process.env.NEXT_PUBLIC_USERS}/api/v1`,
}
interface IUserService {
  saveUser: (body: registerUser) => Promise<void | RegisterResponse | undefined>
  checkUserRegistered: (
    body: checkRegisterUser
  ) => Promise<void | checkUserRegistered>
  updateUser: (
    userId: string,
    body: updateUser
  ) => Promise<updateUser | undefined>
  getUser: (userId: string) => Promise<getUserData>
}

const UserService: IUserService = {
  saveUser: (body) =>
    axiosInstanceClient
      .post<RegisterResponse>(`/User`, body, config)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Registro completado')
          return response.data
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          toast.error(`${error.response.data.message}`)
        }
        if (error.response.status === 413) {
          toast.error('Imagen ingresada es demasiado grande.')
        }
      }),

  checkUserRegistered: (body) =>
    axiosInstanceClient
      .post<checkUserRegistered>(`/User/check-user-registered`, body, {
        baseURL: `${process.env.NEXT_PUBLIC_USERS}/api/v1`,
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.data
      }),

  updateUser: async (userId, body) => {
    try {
      const response = await axiosInstanceClient.put<updateUser>(
        `/User/${userId}`,
        body,
        config
      )
      if (response.status === 200) {
        toast.success('Información actualizada')
      }
      return response.data
    } catch (error) {
      toast.error('Error en la actualización.')
    }
  },

  getUser: async (userId) => {
    const response = await axiosInstanceClient.get<getUserData>(
      `/User/${userId}`,
      config
    )
    return response.data
  },
}

export default UserService
