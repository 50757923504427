import Header from '../ui/molecules/Header'
import TabBar from '../ui/molecules/TabBar'
import AuthenticationProvider from '@/context/AuthenticationContext'
import SearchHomeContextProvider from '@/context/SearchHomeContext'
type MainLayoutProps = {
  children: JSX.Element | JSX.Element[]
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <AuthenticationProvider>
      <SearchHomeContextProvider>
        <div className="mx-auto flex h-screen w-full flex-col items-end bg-tamagotchi md:container">
          <Header />
          {children}
          <TabBar />
        </div>
      </SearchHomeContextProvider>
    </AuthenticationProvider>
  )
}

export default MainLayout
