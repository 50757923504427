/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import { useRouter } from 'next/router'

import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import UserPerfil from '../atoms/UserPerfil'

const Header = () => {
  const router = useRouter()

  const redirectLastPath = () => {
    if (sessionStorage.getItem('lastPath') !== undefined) {
      const lastPath = sessionStorage.getItem('lastPath') || '/'
      router.push(lastPath)
    }
  }

  return (
    <header className="flex w-full items-center justify-between px-4 py-1">
      <section className="cursor-pointer text-sm text-white sm:text-base">
        {router.asPath === '/' ||
        router.asPath === '/cuenta' ||
        router.asPath === '/cuenta?tab=direcciones' ||
        router.asPath === '/cuenta?tab=datos-personales' ||
        router.asPath === '/notificaciones' ? (
          <a
            href={`${process.env.NEXT_PUBLIC_LUEGOPAGO}`}
            target="_blank"
            rel="noopener noreferrer"
            title="ir a luegopago"
          >
            <span className="rounded-[50px] bg-white bg-opacity-20 px-[16px] py-[6px]">
              Ir a Luegopago.com
            </span>
          </a>
        ) : (
          <div onClick={redirectLastPath}>
            <FontAwesomeIcon icon={faArrowLeft} className="w-6" />
          </div>
        )}
      </section>
      <UserPerfil />
    </header>
  )
}

export default Header
