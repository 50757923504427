/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import { ComponentSearchHomeEntity } from '@/types/strapi/Components.types'

export interface ISearchHomeContext {
  setFilterServiceData: Dispatch<
    SetStateAction<ComponentSearchHomeEntity | undefined>
  >
  filterServiceData: ComponentSearchHomeEntity | undefined
}

const SearchHomeContext = createContext<ISearchHomeContext>({
  setFilterServiceData: () => {
    return
  },
  filterServiceData: {
    isActive: true,
    eanCode: '',
    label: '',
    slug: '',
  },
})

export default function SearchHomeContextProvider({
  children,
}: {
  children: ReactNode
}) {
  const [filterServiceData, setFilterServiceData] =
    useState<ComponentSearchHomeEntity>()

  return (
    <SearchHomeContext.Provider
      value={{
        setFilterServiceData,
        filterServiceData,
      }}
    >
      {children}
    </SearchHomeContext.Provider>
  )
}

export function useSearchHome() {
  return useContext(SearchHomeContext)
}
