import { gql } from '@apollo/client'

export default gql`
  query PaymentsTopUps {
    paymentsTopUp {
      principalBanner {
        image {
          url
        }
        imageMobile {
          url
        }
        href
        newTab
        releaseDate
        endDate
        aspectRatioDesktop
        aspectRatioMobile
        description
      }
      rechargeCard {
        name
        label
        icon {
          url
        }
        index
        circleColor
        options {
          label
          icon {
            url
          }
          circleColor
          slug
        }
        slug
        isActive
        description
        collectionId
        hasSearchEngine
        description
      }
      collectionCard {
        name
        categoryId
        icon {
          url
        }
        label
        circleColor
        collectionServices {
          eanCode
          billerCode
          name
          label
          productCode
          productDescription
          categoryId
          minValue
          maxValue
          partialPayment
          collectionType
          modality
          onlyPayment
          specialBill
          isActive
        }
        hasSearchEngine
        isActive
        slug
        description
        placeholder
      }
      topupsRecargas
      topupsPaquetes
      topupsDirecTv
      searchHome {
        eanCode
        label
        slug
        isActive
      }
    }
  }
`

export const HomeTopUpQuery = gql`
  query PaymentsTopUps {
    paymentsTopUp {
      collectionCard {
        slug
      }
    }
  }
`

export const InactivityScreenQuery = gql`
  query PaymentsTopUps {
    paymentsTopUp {
      inactivityScreen
    }
  }
`

export const CollectionIdQuery = gql`
  query PaymentsTopUps {
    paymentsTopUp {
      collectionCard {
        name
        categoryId
        icon {
          url
        }
        label
        circleColor
        collectionServices {
          eanCode
          billerCode
          name
          label
          productCode
          productDescription
          categoryId
          minValue
          maxValue
          partialPayment
          collectionType
          modality
          onlyPayment
          specialBill
          isActive
        }
        hasSearchEngine
        isActive
        slug
        description
        placeholder
      }
    }
  }
`
